<template>
<main-layout>
  <div id="home">
      <headerbar logovisible="true"/>
      <!-- <div class="category">
        <div v-for="c in classCategories" :key="c.ID" class="item">{{c.Name}}</div>
      </div> -->
      <div class="banner">
      </div>
      <div class="article-warpper box">
        <div class="box-header">
          <h2 class="title">智能生活</h2>
        </div>
        <div class="article" v-for="article in articles" v-on:click="articleClick(article)" :key="article.ID">
          <div style="overflow:hidden;height: 33vw;">
            <img class="coverimage" :src="article.ImageUrl"/>
          </div>
          <div class="title">
            {{article.Title}}
          </div>
          <div class="subtitle">
            {{article.SubTitle}}
          </div>
        </div>
      </div>
  </div>
</main-layout>
</template>
<script>
import ArticleService from '../services/ArticleService'
import ContentService from '../services/ContentService'
import MainLayout from '../layouts/Main'
import { mapState, mapAcAtions, mapMutations } from 'vuex'
export default {
  name: 'home',
  components: {
    MainLayout
  },
  computed: {
      ...mapState({
          isLogin: state => state.user.isLogin,
          userName: state => state.user.userName,
          token: state => state.user.token,
          photo: state => state.user.photo,
          nickName: state => state.user.nickName,
      }),
  },
  data () {
      return {
         articles:[],
         banners:[{},{},{}],
         filter:{
           Status:{Value:1},
           UpdateTime:{Sort:-2},
           Sort:{Sort:-2},
           Pager:{PageSize:100}
        }
      }
  },
  methods:{
    articleClick:function(c){
       this.$router.push({name:'articledetail',query:{id:c.ID}});
    },
    bannerClick:function(banner){
       window.location.href=banner.BannerUrl;
    }
  },
  created: function () {
    console.log(this.$store.state.user);
    ArticleService.ArticleList(this.filter).then(data=>{
      this.articles=data.Data.Results;
    });
    ContentService.BannerList({UpdateTime:{Sort:-2}}).then(s=>{
      this.banners=s.Data.Results;
    });
  },
  mounted: function () {//dom已渲染
    //   if(!this.isLogin){
    //     this.$router.push({ name: 'activityLogin'})
    //   }
      // else{
      //     this.$store.dispatch('user/getUserInfo');
      // }
  },
}
</script>
<style lang="css" scoped>
@import url("/style/css/swiper-pagination.css");
.box{

}
.box .box-header{
   width:100%;
}
.box .box-header .title{
  font-size: 5.333vw;
    font-family: qsdm,PingFangSC-Semibold;
    font-weight: 700;
    padding:0 10px;
    color: #212831;
}
#home{
  display: flex;
  flex-direction: column;
}
.category{
    height: 38vw;
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    overflow: hidden;
   
}
.category .item{
    height: 20vw;
    width: 20vw;
    border:solid 1px #eee;
    display: flex;
    justify-content: center;
    align-items: center;
     -webkit-box-shadow: 0 1.067vw 2.667vw 0 rgb(0 0 0 / 6%);
    box-shadow: 0 1.067vw 2.667vw 0 rgb(0 0 0 / 6%);
    border-radius: 2.133vw;
}
.banner{
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
    height: 200px;
    display: flex;
    width: 100%;
    justify-content:center;
    align-items: center;
}

.article-warpper{
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction:row;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
    padding-left: 0;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 7.2vh;
}
.article-warpper .article{
    background: #fff;
    -webkit-box-shadow: 0 1.067vw 2.667vw 0 rgb(0 0 0 / 6%);
    box-shadow: 0 1.067vw 2.667vw 0 rgb(0 0 0 / 6%);
    border-radius: 2.133vw;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
    width: 47%;
    height: 13rem;
    margin-left: 10px;
    overflow: hidden;
}
.article-warpper .article:first-of-type{
  margin-left: 0px;
}
.article-warpper .article .title{
  /* font-size: 16px;
    font-weight: 600;
    margin-top: 10px; */
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: 10px;
    line-height: 1.5;
}
.article-warpper .article .subtitle{
  /* font-size: 16px;
    font-weight: 600;
    margin-top: 10px; */
    font-size: 0.6rem;
    font-weight: 500;
    margin-top: 0.5vh;
    line-height: 1;
}
.article-warpper .article .coverimage{
    width: 100%;
    border-radius: 2.133vw;
    -o-object-fit: contain;
    object-fit: cover;
    height: 100%;
}
</style>
